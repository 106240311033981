import React ,{Component} from 'react';

import { NavLink,Outlet } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Cartcount from './Cartcount';
import ReactDOM from "react-dom";
import logo from './images/logo.jpg';
import bar from './images/top-bar-icon-1.jpg';
import bag from './images/top-bar-icon-2.jpg';
import settings from './images/top-bar-icon-3.jpg';
import cart from './images/top-bar-icon-4.jpg';
import floatingUser from './images/floating-icon.jpg';
import Categoryicon from './images/categeory.jpg';
import Ordericon from './images/order.jpg';
import customericon from './images/customer.jpg';
import producticon from './images/product.jpg';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { styled, useTheme } from '@mui/material/styles';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Link from '@mui/material/Link';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import InventoryIcon from '@mui/icons-material/Inventory';
import ListItem from '@mui/material/ListItem';


import ListItemText from '@mui/material/ListItemText';
const createHistory = require("history").createBrowserHistory;
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



export default class MiniDrawer extends Component {	
	

  constructor(props) {
    super(props)
    this.state = {

        open:false,reason:'',anchorEl:null,menuopen:null,submenuopen:false,cartqty:0,menuopen1:null,
		network:1,name:'',redirect:false,pagename:'',mainmenu:0,year:new Date().getFullYear()}
    
    
}
//cafd05a4-02bb-487c-8f14-4adfbe37d519/8fc84764-a9db-4988-a7e7-92c8e3bdc960
//:dqX&=+M5K)k.EoKb1RH
componentDidMount() {
this.ApiOrderProduct();
}	
componentWillReceiveProps(props) {
	this.setState({refresh : props.refresh });
	
	this.ApiOrderProduct();
}
ApiOrderProduct(){
	
	 axios.get(Serverurl+"ajaxorderproduct.php?action=cartlist&sessionid="+localStorage.getItem("ISIsession"))
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
		
			  cartqty: resp.data[0].cartcount,
			 
			});
			
	  localStorage.setItem("IScartqty", resp.data[0].cartcount)
	 
     }else{
		 
	 }

    });
	
}
 handledownclick = (e) => {
	 this.setState({ open:true,submenuopen:true });
 }
 
handleDrawerOpen(val){
        this.setState({ id: val });  
 // alert(val);
       

if(val===0)
{
	this.setState({ open:!this.state.open,submenuopen:true });
}
else{
	this.setState({ open:true,submenuopen:true });
	
}


      }
	  

handleDrawerClose = (e) => {
   
  this.setState({ open:!this.state.open,submenuopen:true });
//const {onToggle, setCollapsibleElement, progress } = this.state;


};

 handleorderClick = (e) => {
   
  this.setState({ anchorElord:e.currentTarget ,menuopen1:true});
};
 handleorderClose = (e) => {
   
  this.setState({ anchorElord:null ,menuopen1:false});
};
 handleClick = (e) => {
   
  this.setState({ anchorEl:e.currentTarget ,menuopen:true});
};

 handleClose = (e) => {
   
  this.setState({ anchorEl:null ,menuopen:false});
};
  

  
  
 userlogout =(anchorEl,menuopen) => (event) => {
	
	
	if(menuopen==true){
	
	let myItem = localStorage.getItem('ISIusername');
	let myItempass = localStorage.getItem('ISIpassword');
localStorage.clear();
localStorage.setItem('ISIusername',myItem);
  localStorage.setItem('ISIpassword',myItempass);
   //location.href = "/Signin/"
	let history = createHistory();
        history.push("#");
        let pathUrl = window.location.href;
        window.location.href = pathUrl;   
	
	}
  };
	
render() {
const {onToggle, setCollapsibleElement, progress } = this.state;

  return (
   <React.Fragment> 
 
  
  
       <div className="masthead">
	   
	    <div className="mastheadleft">
	   <img src={logo} />
	   </div>
	   
	   
	    <div className="mastheadright"> {localStorage.getItem("ISIrole")==="2" &&(
		<div className="float-sm">
		<div className="fl-fl float-tw">
  
    <a href="#/Salesdashboard"  title="Select Customer"><img src={floatingUser}  className="iconvertical"/> Customers!</a>
  </div>
		
		</div>)}
<div className="headerrt">
	   <ul>
	 
	   <li>
	   {localStorage.getItem("ISIrole")==="1" &&(
	   <a href='#/Admindashboard' > 
	 <Tooltip title="Dashboard">  <img src={bar} /> </Tooltip>
	   </a>
	   )}
	   {localStorage.getItem("ISIrole")==="2" &&(
	   <a href='#/Salesdashboard' > 
	  <Tooltip title="Dashboard">   <img src={bar} /></Tooltip>
	   </a>
	   )}  {localStorage.getItem("ISIrole")==="3" &&(
	   <a href='#/Userdashboard' > 
	   <Tooltip title="Dashboard">  <img src={bar} /></Tooltip>
	   </a>
	   )}
	   </li>
	     {localStorage.getItem("ISIrole")==="1" &&(
	   
	     <li> <a href='#/Category' > 
	 <Tooltip title="Categories"> <img src={Categoryicon} /></Tooltip>
	   </a>  </li>)}
	    <li>
	    {localStorage.getItem("ISIrole")!=="1" &&(
	   <a href='#/product' > 
	 <Tooltip title="Products"> <img src={producticon} /></Tooltip>
	   </a>  )}
    {localStorage.getItem("ISIrole")==="1" &&(
	   <a href='#/Adminproduct' > 
	 <Tooltip title="Product Management"> <img src={producticon} /></Tooltip>
	   </a>  )}

	   </li>
	       {localStorage.getItem("ISIrole")==="1" &&(
	   
	     <li> <a href='#/Cutsomerdisplay' > 
	 <Tooltip title="Customer Management"> <img src={customericon} /></Tooltip>
	   </a>  </li>)}
	     {localStorage.getItem("ISIrole")==="1" &&(
	   
	     <li> <a href='#/Adminorders' > 
	 <Tooltip title="Order Management"> <img src={Ordericon} /></Tooltip>
	   </a>  </li>)}
	   {localStorage.getItem("ISIrole")!=="1" &&(<React.Fragment>
 {(localStorage.getItem("ISorcount")!=="0" && localStorage.getItem("ISCustid")!==null) && (

	   <li>
	   
	   <div className="popupwindow"> <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        
       
          <div  className="submenus" onClick={this.handleorderClick} size="small" sx={{ ml: 2 }}>
       <Tooltip title="Order History">  <p className="pointer"> <img src={bag} /></p></Tooltip>
          </div>
       
      </Box>
	  
 <Menu
        anchorEl={this.state.anchorElord}
        open={this.state.menuopen1}
        onClose={this.handleorderClose}
        onClick={this.handleorderClose}
         PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,top:'50px!important',right:'270px!important',left:'unset!important',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 50,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        
       
          
 <MenuItem>
         
		  <div className="setingdiv">
		    <a href='#/Orderhistory' className="colorlink"> 
			
          Order History
          </a>
		  </div>
        </MenuItem>
		
       		
        <MenuItem>
         <div className="setingdiv">
		 
		    <a href='#/smartbasket' className="colorlink"> 
			
            Smart Basket
          </a>
		  </div>
        </MenuItem>
		
		 	
          
		 
      </Menu>
	  </div>
	   
	   
		
	   
	   </li>)}</React.Fragment>)}
	   <li> <div className="popupwindow"> <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        
       
          <div  className="submenus" onClick={this.handleClick} size="small" sx={{ ml: 2 }}>
       <Tooltip title="Your Account"> <p className="pointer"> <img src={settings} /></p></Tooltip>
          </div>
       
      
	  
 <Menu
        anchorEl={this.state.anchorEl}
        open={this.state.menuopen}
        onClose={this.handleClose}
        onClick={this.handleClose} className="icon-submenu"
         PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 115,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        
       
   {localStorage.getItem("ISIrole")!=="1" &&(        
 <MenuItem>
         
		  <div className="setingdiv">
		    <a href='#/Myaccount' className="colorlink"> 
			
           My Account
          </a>
		  </div>
        </MenuItem>
		
   )}
    {localStorage.getItem("ISIrole")==="2" &&(
    <MenuItem>
         
		  <div className="setingdiv">
		    <a href='#/Myorder' className="colorlink"> 
			
         My Orders
          </a>
		  </div>
        </MenuItem>   )}
        <MenuItem>
         <div className="setingdiv">
		 
		    <a href='#/Changepassword' className="colorlink"> 
			
            Change Password
          </a>
		  </div>
        </MenuItem>
		
		 	
           <MenuItem>
		 
		    <div className="setingdiv">
			<a href='#/Logout'>
		  
		   
            Logout
      
		   </a>
		   </div>
		 
		 
        </MenuItem>
		 
      </Menu></Box>
	  </div>
	   </li>
	 {localStorage.getItem("ISIrole")!=="1" &&(  
	     <React.Fragment>
	  {this.state.cartqty===0 && (
	 <li>
	   <img src={cart}/><span className="cartcount">{this.state.cartqty}</span>
	</li>   )}
	 </React.Fragment> )}  {localStorage.getItem("ISIrole")!=="1" &&(    <React.Fragment>
	  
	  {this.state.cartqty!==0 && ( <li>
	   <a href="#/MyCart">
	  <Tooltip title="Cart">  <img src={cart}/><span className="cartcount">{this.state.cartqty}</span></Tooltip></a>
		</li>   )}
   </React.Fragment>)}
	   <li> <p className="welcometext">Welcome </p>
	   <p className="welcometxt">{localStorage.getItem("ISINames")}</p></li>
	   </ul>
	  
	  
	   
	  
	   
	   </div>
	   
	   <div className="headerleft">
		
			<h1><Outlet/></h1>
</div>
	     
	   <div className="clear"></div>
	   
	   
	   
	   
	   
	   
	   </div>
	   
	   <div className="clear"></div>
	   
	   </div>
	   
	   
	   
	  
      			
	



  

  </React.Fragment>  
  );
}
}
