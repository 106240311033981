import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';


import InputLabel from '@mui/material/InputLabel';

import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import MenuItem from '@mui/material/MenuItem';

import { styled } from '@mui/material/styles';
import CreateIcon from '@mui/icons-material/Create';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';



const useStyles = makeStyles(theme => ({
 
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  
  form: {
    width: '500%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
}));





export default class  Customer extends Component {

   constructor(props) {
      super(props)
	  
          this.state = {redirect: false,AddressData:[],
     page:0 ,prevpage:0,totaldata:0,
	 errormessage:"",erropen:false,csid:'',role:'',
	  order:'desc',cs_id:0,cs_name:'',cs_email:'',cs_password:'',cs_username:'',cs_role:0,pricegrp:0,
	      btnsavedisbaled:false,btnsavetxt:'Save',
      }
	  
	  
	     
      
  }
  
  
  
  

 
  
  componentDidMount() {
  
  
  
  if(localStorage.getItem("ISIuserid")===null)
	{
		
	
		this.setState({
            redirect: true,
			pagename:'Login'
          })
	}else if(	localStorage.getItem("ISIrole")!=="1")
		
		{
			this.setState({
            redirect: true,
			pagename:'Login'
          })
			
		}document.title = "Baawarchi - Category";
	
	 let search = window.location.href;
	 let cusid = this.getParameterByName('csid',search);
	if(cusid!==null)
	{
this.CustomerGetData(cusid);
	}
		
}
  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


 CustomerGetData(cusid) {
	

	  
	   axios.get(Serverurl+'ajaxcustomer.php?action=list&cs_id='+cusid)
  .then(resp => {  
		
    if(resp.data.length>0){
    this.setState({ 
     
  
    cs_id:resp.data[0].cs_id,
	cs_name:resp.data[0].cs_name,
	cs_email:resp.data[0].cs_email,
	
	cs_phonenumber:resp.data[0].cs_phonenumber,
	cs_role:resp.data[0].cs_role, 

	cs_username:resp.data[0].cs_username,
	cs_password:resp.data[0].cs_password,
pricegrp:resp.data[0].cs_pricegroup
 
    });
	
  
	}else{
	 this.setState({  
	  cs_id:0,
	  cs_name:'',
		cs_email:'',
		cs_phonenumber:'',
		cs_role:'',
		cs_username:'',
		cs_password:'',
	pricegrp:0
	  }); 
 
  
    }
});
	  
 }





myChangeHandler = (event) => {
	
	const repho = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;  
 
 
 if(nam==="cs_phonenumber")
  {
	  if (event.target.value === '' || repho.test(event.target.value)) {
	  this.setState({[nam]: val}); 
  }
  }
 
  else{
	  
	   this.setState({[nam]: val});
  }

  

}

 handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  }


 
  SubmitForm= (e) => {


	let cs_id = this.state.cs_id;
	let cs_name = this.state.cs_name;
	let cs_email=this.state.cs_email;
	let cs_phonenumber=this.state.cs_phonenumber;
	let cs_role = this.state.cs_role;

	let cs_username=this.state.cs_username;
	let cs_password=this.state.cs_password;
	let pricegrp=this.state.pricegrp;
	
	const remail= /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
	

this.setState({errormessage:"",erropen:false});
	if(cs_name===''){
						
		this.setState({errormessage:"Please Enter Customer Name.",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
		
		
					
			if(cs_email===''){
						
		this.setState({errormessage:"Please Enter Email Id",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
				 else  
  {
 if (!remail.test(cs_email)) {
	 this.setState({errormessage:"Please Enter Valid Email Id",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
  }
	  
  }
				
		if(cs_phonenumber===''){
						
		this.setState({errormessage:"Please Enter Phone Number",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
				
				
						
		if(cs_role===''){
						
		this.setState({errormessage:"Please Select Role",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
			if(this.state.cs_role==="3"){					
		if(pricegrp==='0'){
						
		this.setState({errormessage:"Please Select Price Group",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
			}}
				
			if(cs_username===''){
						
		this.setState({errormessage:"Please Enter  User Name",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
				
			if(cs_password===''){
						
		this.setState({errormessage:"Please Enter  Password",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
				
		this.setState({errormessage:"",erropen:false,btnsavedisbaled:true,btnsavetxt:'Processing...',});
		
				
		
										
				
				var formData = new FormData();
				
				formData.append('action','insertorupdate')
							
				
				if(cs_id===0){
					formData.append('cs_id',"0")
					}
				else{
					
					formData.append('cs_id',cs_id)
				
				}
			
				formData.append('cs_pricegroup',pricegrp)
					formData.append('cs_name',cs_name)
					formData.append('cs_email',cs_email)
					formData.append('cs_phonenumber',cs_phonenumber)
					formData.append('cs_role',cs_role)
				
					formData.append('cs_username',cs_username)
					formData.append('cs_password',cs_password)
					
				
				
					formData.append('userid',localStorage.getItem("ISIuserid"))
					axios.post(Serverurl+"ajaxcustomer.php", formData, {
				}).then(res => {
					
						if(res.data!=-1)
					{
						
					let strreturn=res.data;
					strreturn=strreturn;
					
					
					
						this.setState({redirect:true,pagename:'/Cutsomerdisplay'});
					
					this.setState({open:false,btnsavedisbaled:false,btnsavetxt:'Save',});
				
						
							
							
						}
					
					
				})
  
					
  }
  
  
 
  
 
 	


render (){
if(this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }

return(


<React.Fragment>

<div className="floatright">
<a href="#Cutsomerdisplay">
  <Button  variant="contained"   className="generalbtn" >
				Back 	
		</Button>
	
</a>
  
</div>
<div className="clear"></div>

	<p>  <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
			  
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>	


	<div className="adminform">
	
	
	<ul>
	
	<li>
			<p><span className="validation">*</span> Name</p>
			<p><TextField name="cs_name" 
		   onChange={this.myChangeHandler} 
			inputProps={{tabIndex: "1",maxLength:150}}
		   size="small"
		   value={this.state.cs_name}
				fullWidth
			
					
			/></p>
			</li>
	
	
	
	
	<li>
			<p><span className="validation">*</span>Email Id</p>
			<p><TextField name="cs_email" 
		   onChange={this.myChangeHandler} 
			
		   fullWidth
		   size="small"
		   value={this.state.cs_email}
			inputProps={{tabIndex: "2",maxLength:150}}	
			
					
			/></p>
			</li>	
	
	
		<li>
			<p><span className="validation">*</span>Phone Number</p>
			<p><TextField name="cs_phonenumber" 
		   onChange={this.myChangeHandler} 
			inputProps={{tabIndex: "3",maxLength:15}}
		   fullWidth type="number"
		   size="small"
		   value={this.state.cs_phonenumber}
				
			
					
			/></p>
			</li>
	
				




		
		<li>	
		<p><span className="validation">*</span>Role</p>
		<p><FormControl  className="stadrp">			   
		<Select
		variant="outlined"
		native
		value={this.state.cs_role}
		onChange={this.myChangeHandler}
		name='cs_role'
		inputProps={{tabIndex: "4"}}
		InputLabelProps={{
		shrink: true,
		}}
		>
		<option value={0}>&nbsp;Role</option>
		<option value={1}>Admin</option>
		<option value={2}>Sales</option>		
		<option value={3}>Customer</option>		
		</Select>
		</FormControl></p>	
		</li>					
		{this.state.cs_role==="3" && (<li >	
		<p><span className="validation">*</span>Price Group</p>
		<p><FormControl  className="stadrp">			   
		<Select
		variant="outlined"
		native
		value={this.state.pricegrp}
		onChange={this.myChangeHandler}
		name='pricegrp'
		inputProps={{tabIndex: "4"}}
		InputLabelProps={{
		shrink: true,
		}}
		>
		<option value={0}>Select</option>
		<option value={1}>Distributor</option>
		<option value={2}>Retailer</option>		
		<option value={3}>Whole Saler</option>		
		</Select>
		</FormControl></p>	
		</li>
		)}
	
		<li>
			<p><span className="validation">*</span>User Name</p>
			<p><TextField name="cs_username" 
		   onChange={this.myChangeHandler} 
			inputProps={{tabIndex: "5",maxLength:150}}
		   fullWidth
		   size="small"
		   value={this.state.cs_username}
				
			
					
			/></p>
			</li>
	
	
		<li>
			<p><span className="validation">*</span>Password</p>
			<p><TextField name="cs_password" 
		   onChange={this.myChangeHandler} 
			inputProps={{tabIndex: "6",maxLength:15}}
		   fullWidth
		   size="small" type="password"
		   value={this.state.cs_password}
				
			
					
			/></p>
			</li>
	
	
	
	<li className="stadrp">
			<Button className="primarybtn"  onClick={this.SubmitForm} variant="contained" disabled={this.state.btnsavedisbaled}>
				{this.state.btnsavetxt} 
		</Button>
	</li>
	
	
	
	</ul>
	
	
	
	</div>

	
	
	
        
	
	<div className="clear"></div>
	
	





	

	
	
	
	
	
	
	
				
		 

	
	
	
	
      
  

</React.Fragment>

) }
 
} 
  
	